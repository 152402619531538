// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-es-js": () => import("./../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-es-aboutus-js": () => import("./../src/pages/es/aboutus.js" /* webpackChunkName: "component---src-pages-es-aboutus-js" */),
  "component---src-pages-es-contacts-js": () => import("./../src/pages/es/contacts.js" /* webpackChunkName: "component---src-pages-es-contacts-js" */),
  "component---src-pages-es-services-js": () => import("./../src/pages/es/services.js" /* webpackChunkName: "component---src-pages-es-services-js" */),
  "component---src-pages-fr-js": () => import("./../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-fr-aboutus-js": () => import("./../src/pages/fr/aboutus.js" /* webpackChunkName: "component---src-pages-fr-aboutus-js" */),
  "component---src-pages-fr-contacts-js": () => import("./../src/pages/fr/contacts.js" /* webpackChunkName: "component---src-pages-fr-contacts-js" */),
  "component---src-pages-fr-services-js": () => import("./../src/pages/fr/services.js" /* webpackChunkName: "component---src-pages-fr-services-js" */),
  "component---src-pages-gb-js": () => import("./../src/pages/gb.js" /* webpackChunkName: "component---src-pages-gb-js" */),
  "component---src-pages-gb-aboutus-js": () => import("./../src/pages/gb/aboutus.js" /* webpackChunkName: "component---src-pages-gb-aboutus-js" */),
  "component---src-pages-gb-contacts-js": () => import("./../src/pages/gb/contacts.js" /* webpackChunkName: "component---src-pages-gb-contacts-js" */),
  "component---src-pages-gb-services-js": () => import("./../src/pages/gb/services.js" /* webpackChunkName: "component---src-pages-gb-services-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pt-js": () => import("./../src/pages/pt.js" /* webpackChunkName: "component---src-pages-pt-js" */),
  "component---src-pages-pt-aboutus-js": () => import("./../src/pages/pt/aboutus.js" /* webpackChunkName: "component---src-pages-pt-aboutus-js" */),
  "component---src-pages-pt-contacts-js": () => import("./../src/pages/pt/contacts.js" /* webpackChunkName: "component---src-pages-pt-contacts-js" */),
  "component---src-pages-pt-services-js": () => import("./../src/pages/pt/services.js" /* webpackChunkName: "component---src-pages-pt-services-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

